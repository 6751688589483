import { VSpace } from '@x/components';
import { Card, Spin, Typography } from 'antd';
import { UnauthorizedPage } from 'components/Layout/UnauthorizedPage';
import React from 'react';

export function AuthStatus({
  message,
}: {
  message?: string;
}): React.JSX.Element {
  return (
    <UnauthorizedPage top={'35%'}>
      <Card style={{ textAlign: 'center' }}>
        <VSpace>
          <Spin size="large" />
          {message && (
            <Typography.Text type="secondary">{message}</Typography.Text>
          )}
        </VSpace>
      </Card>
    </UnauthorizedPage>
  );
}
